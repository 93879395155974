.body_container {
  margin: 0 80px;
}

.bid_header_container {
  font-family: sans-serif;
  font-size: 28px;
  font-weight: bold;
}

.mybid_outer_wrapper_container {
  display: flex;
  width: 100%;
}

.mybid_outer_wrapper_container .mybid_side_dashboard {
  border: 1px solid #e4e6e8;
  background-color: #f5f5fa;
  width: 30%;
  border-radius: 15px;
  height: 200px;
}

.mybid_side_dashboard .mybid_dashboard_manu {
  width: 100%;
  height: 25%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-family: sans-serif;
  font-size: 14px;
}

.mybid_side_dashboard .mybid_dashboard_manu:hover {
  background-color: gainsboro;
}

.mybid_dashboard_manu:nth-child(1):hover {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.mybid_dashboard_manu:last-child:hover {
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.mybid_dashboard_manu1 {
  display: flex;
  align-items: center;
}

.my-bid-body-container {
  width: 100%;
  margin-left: 30px;
}

.bid_header_body {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 58px;
}

.bid_header_body .input_container {
  width: 81%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #e4e6e8;
  border-radius: 31px;
}

.input_container .input_field {
  width: 90%;
  height: 58px;
  border: none;
  padding-left: 20px;
  border-radius: 31px;
  font-size: 16px;
}

.input_container .input_field:focus {
  outline: none;
}

.search_bar_container {
  border-radius: 50%;
  background-color: #ec952d;
  height: 50px;
  width: 50px;
  position: relative;
  margin-right: 5px;
  cursor: pointer;
}

.search_icon_container {
  position: absolute;
  top: 14px;
  left: 14px;
}

.filter_outer_container {
  background-color: #ec952d;
  border-radius: 5rem;
  height: 50px;
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.filter_text {
  color: white;
  font-family: sans-serif;
  padding-left: 5px;
}

.Navigation_Container {
  color: #8083a3;
  display: flex;
  align-items: center;
  height: 42px;
  width: 100%;
  margin: 20px 0;
}

.Navigation_Container .Navigation-manu {
  font-weight: 400;
  font-family: sans-serif;
  font-size: 12px;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0px 15px;
  margin: 0 20px 0 0;
  border-radius: 3rem;
  cursor: pointer;
}

.Navigation-manu1 {
  background-color: #ec952d !important;
  color: white !important;
}

.graterthan_icon {
  margin-right: 20px;
}

.browse_projects {
  margin: 0 10px 0 20px;
}

/* Project Div CSS */

.mouse_event .project_common_container h2 {
  margin: 0 0 10px 0;
  padding: 0;
  font-size: 18px;
  font-family: sans-serif;
}

.project_common_container {
  padding: 20px 25px;
}

.project_container1 {
  border-bottom: 1px solid #e4e6e8;
}

.price_negotiation {
  color: #8083a3;
  font-size: 12px;
  font-family: sans-serif;
}

.profile_wrapper {
  display: flex;
  align-items: center;
}

.profile_image {
  border-radius: 50%;
  margin: 10px 10px 10px 0;
}

.project_owner_name {
  font-size: 16px;
  font-weight: bold;
  font-family: sans-serif;
}

.bid_negititation {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}

.price_negotiation_outer_wrapper {
  max-width: 240px;
}

.price_negotiation_outer_wrapper .price_cantainer {
  font-size: 18px;
  font-weight: bold;
  font-family: sans-serif;
  margin-top: 10px;
}

.mouse_event {
  border: 1px solid #e4e6e8;
  border-radius: 16px;
  margin: 20px 0;
}

.hoverClass {
  cursor: pointer;
}

/* Filter Div CSS */

.projects_filter_wrapper {
  border: 1px solid #e4e6e8;
  border-radius: 16px;
  padding: 20px 25px;
  margin: 20px 0;
}

.projects_filter_wrapper .filterHeading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.filterHeading .text_field {
  font-family: sans-serif;
  font-size: 18px;
  font-weight: bold;
}

.cancel_btn {
  cursor: pointer;
}

.formControl_container .MuiInput-underline:before,
.formControl_container .MuiInput-underline:after {
  border-bottom: 1px solid #e4e6e8;
}

.selectionTag_container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.btns_submit_container {
  display: flex;
  margin: 30px 0 10px 0;
  justify-content: flex-end;
}

.reset-btn {
  color: black;
  background-color: #f2f2f2;
  padding: 10px 30px;
  border-radius: 5rem;
  font-family: sans-serif;
  font-size: 12px;
  font-weight: bold;
  margin-right: 20px;
  cursor: pointer;
}

.Apply-btn {
  background-color: #ec952d;
  color: white;
  padding: 10px 30px;
  border-radius: 5rem;
  font-family: sans-serif;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
}

/* MyBid2 */

.body_wrapper {
  padding: 0 200px;
  font-family: sans-serif;
}

.skillsset_wrapper {
  display: flex;
  margin: 20px 0;
  gap: 10px;
  flex-wrap: wrap;
}

.skill_set_requirement {
  padding: 10px 20px;
  background-color: #f6f6f9;
  border-radius: 5rem;
  font-family: sans-serif;
  font-size: 12px;
}

.projects_filter_wrapper .text_pera {
  font-size: 14px;
  font-family: sans-serif;
  margin: 20px 0;
}

.devloper_coverlatter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e4e6e8;
}

.devloper_coverlatter .dev_details {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 20px;
}

.reduce_margin {
  margin-right: 20px !important;
}

.company_logo_container {
  font-size: 20px !important;
  font-family: sans-serif !important;
}

.bck-clr{
  background-color: #f8f7fc;
  padding: 20px 25px;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.My-cv-latter {
  padding: 20px 25px;
}

.My-cv-latter .text_container {
  font-size: 16px;
  font-family: sans-serif;
}

.freelancer_resume {
  font-size: 18px;
  font-weight: bolder;
  font-family: sans-serif;
}

.personal-details-wrapper {
  border: 1px solid #e4e6e8;
  border-radius: 16px;
  margin: 20px 0;
}

.profie_picture {
  border-radius: 5rem;
}

.commanClass {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 10px 0;
  font-family: sans-serif;
}

.aboutTheSponsor_Box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.aboutTheSponsor_inner_Box {
  display: flex;
  align-items: center;
}

.projects_ratings {
  display: flex;
  align-items: baseline;
}

.expert_name_container {
  font-size: 18px;
  font-weight: 500;
}

.location_container {
  color: #8083a3;
  font-size: 14px;
  font-family: sans-serif;
}

.fontstyle_wrapper {
  font-size: 22px;
  font-weight: 400;
  font-family: sans-serif;
}

/* Mildstones CSS  */
.mildstone_outer_wrapper {
  background-color: #f8f8fb;
  border: none !important;
}

.date_text {
  font-size: 14px;
  font-family: sans-serif;
  color: #8083a3;
  margin: 0 10px;
}

.myBid_verify {
  margin-left: 20px;
}

.verified-btn {
  font-size: 10px;
  font-weight: bold;
  color: #ffffff;
  font-family: sans-serif;
  background-color: #16a700;
  height: 24px;
  width: 63px;
  border: none;
  border-radius: 30px;
}

.upload_tag_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #e4e6e8;
  border-radius: 10px;
  height: 48px;
  background-color: #ffffff;
  cursor: pointer;
  padding: 0 10px;
  margin: 15px 0px 0 0;
}

.upload_tag_container:nth-child(3) {
  margin-right: 0 !important;
}

.size_div {
  font-size: 10px;
  color: #8083a3;
  font-family: sans-serif;
}

.file_name_div {
  font-size: 14px;
  font-weight: 500;
  font-family: sans-serif;
}

.btn-common-color {
  background-color: #f2f2f2;
  font-size: 12px;
  font-weight: bold;
  font-family: sans-serif;
  height: 36px;
  width: 111px;
  border-radius: 30px;
  border: none;
  /* margin-left: 10px; */
  cursor: pointer;
}

.sendNewBid {
  color: black;
}

.reject_btn {
  color: #ff4e00;
}

.reject_btn2 {
  background-color: #ff4e00;
  color: white;
  cursor: default;
}

.accept_btn {
  color: #ffffff;
  background-color: #ec952d !important;
}

.Sent_to_Sponsor_btn {
  font-size: 12px;
  font-weight: bold;
  font-family: sans-serif;
  padding: 11px 20px;
  border-radius: 30px;
  border: none;
  margin-left: 10px;
  color: #ffffff;
  background-color: #ec952d !important;
}

.btn-common-color1 {
  background-color: #f2f2f2;
  font-size: 12px;
  font-weight: bold;
  font-family: sans-serif;
  height: 36px;
  width: 75px;
  border-radius: 30px;
  border: none;
  margin-left: 10px;
  cursor: pointer;
}

.newBid_container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.MuiTooltip-arrow {
  border: 1px solid #e4e6e8;
}

.input_bid_amount {
  width: 290px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
}

/* Notification CSS */
.alert_box_wrapper .MuiAlert-standardSuccess {
  color: white;
  background-color: #16a700;
  border-radius: 30px;
}

.alert_box_wrapper .MuiAlert-standardError {
  color: white;
  background-color: #ff4e02;
  border-radius: 30px;
}

.alert_box_wrapper .MuiAlert-standardSuccess .MuiAlert-icon {
  color: white;
}

.alert_box_wrapper .MuiAlert-standardError .MuiAlert-icon {
  color: white;
}

.alert_message_Box .MuiAlert-message {
  font-size: 16px !important;
  font-weight: bold;
}

/* Sponsor CSS Screen 3 */
.readonly_ratings {
  font-size: larger;
  margin-right: 10px;
}

.text_content {
  font-family: sans-serif;
  font-size: 15px;
  color: black;
  font-weight: lighter;
  margin: 10px 0;
}

.sponsorProjectHeading {
  font-family: sans-serif;
  font-size: 15px;
  color: black;
  font-weight: 600;
}

.project_owner_name_div {
  font-family: sans-serif;
  font-size: 15px;
  color: #8084a3;
  font-weight: bold;
}

.freelancer_resume1 {
  margin-bottom: 20px !important;
}

.personal_details_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.personal_details_container h1,
.heading1 {
  font-size: 22px;
  font-weight: bold;
  font-family: sans-serif;
  margin: 0;
}

.personal_details_container p {
  padding: 0 !important;
}

.price_tag_content {
  background-color: #e9e6e6;
  padding: 15px 20px;
  border-radius: 10px;
}

.amount_to_paid {
  color: #00a6a2;
  font-weight: bold;
  font-family: sans-serif;
  text-align: center;
  padding-bottom: 5px;
  text-decoration: underline;
}

.price_tag_content .Praposed_Bid {
  font-family: sans-serif;
  color: #8084a3;
  font-size: 14px;
  text-align: center;
}

.frelancer_details {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}

.margin-container {
  margin: 10px 0;
}

.like_div_wrapper {
  border-radius: 50%;
  background-color: #f2f2f2;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  cursor: pointer;
}

.Negotiate_Bid {
  background-color: #f2f2f2;
  font-size: 12px;
  font-weight: bold;
  font-family: sans-serif;
  padding: 15px 40px;
  border-radius: 30px;
  border: none;
  margin-left: 10px;
  cursor: pointer;
  color: black;
  width: fit-content;
}

.Hire_Freelancer {
  background-color: #00a7a2;
  color: white;
  padding: 15px 40px;
  border: none;
  border-radius: 30px;
  font-size: 12px;
  font-weight: bold;
  font-family: sans-serif;
  cursor: pointer;
  margin-left: 10px;
  width: fit-content;
}

.Accepted-btn {
  color: #14a803 !important;
  background-color: #f0faf1 !important;
  cursor: default;
}

.popover_div .MuiPopover-paper {
  border-radius: 40px !important;
}

.popover_input_field {
  border: none;
  padding-left: 20px;
  border-radius: 40px;
  height: 30px;
}

.popover_input_field:focus-visible {
  outline: none;
}

.outer-border-newBid {
  border: 2px solid #e4e6e8;
  border-radius: 40px;
  display: flex;
  width: 85%;
}

.sendnewbid1 {
  color: white;
  background-color: #00a6a2;
}

.rejected_by_freelancer {
  font-size: 12px;
  font-weight: bold;
  font-family: sans-serif;
  padding: 10px 20px;
  border-radius: 30px;
  border: none;
  color: #d90000;
  background-color: #fdf0f0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal_paper {
  border-radius: 14px;
  height: 360px;
  width: 530px;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
    0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.modal_paper h3 {
  font-size: 20px;
  font-weight: bold;
  font-family: sans-serif;
  margin: 40px 0 40px 0;
}

.accept_bid {
  width: 80px;
  height: 80px;
  border: 2px solid #17a700;
  border-radius: 80px;
}

.accept_bid1 {
  width: 80px;
  height: 80px;
  border: 2px solid #d80100;
  border-radius: 80px;
}

/* Send An Offer CSS */
.back-color {
  background-color: #f5f5fa;
}

.Payment_termsAndCondition {
  color: #8083a3;
  font-size: 14px;
  font-family: sans-serif;
  padding: 10px 0;
}

.Payment_termsAndCondition span {
  text-decoration: underline;
  color: #00a7a2;
  font-weight: 600;
}

.sendAnOffer_header_wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.SendAnOffer_container {
  font-size: 28px;
  font-weight: bold;
  font-family: sans-serif;
  margin-left: 20px;
}

.price_negotiation_outer_wrapper .price_cantainer1 {
  font-size: 16px;
  font-weight: bold;
  font-family: sans-serif;
  margin-top: 10px;
}

.payment_responsive {
  width: 60%;
  display: flex;
  justify-content: space-between;
}

.fixedPrice {
  display: flex;
  max-width: 280px;
  align-items: center;
  margin-bottom: 15px;
}

.radio-icon {
  margin: 0;
  accent-color: #00a7a2;
  min-height: 20px;
}

.radio_btn_for_payment {
  display: flex;
  align-items: center;
  padding: 10px 0;
}

/* radio btn CSS  */
input[type="radio"] {
  margin: 0;
  width: 20px;
  height: 20px;
  appearance: none;
  border-radius: 50%;
  outline: none;
  border: 1px solid #606161;
}

input[type="radio"]:before {
  content: "";
  display: block;
  width: 60%;
  height: 60%;
  margin: 20% auto;
  border-radius: 50%;
}

input[type="radio"]:checked {
  border: 1px solid #00a7a2;
}

input[type="radio"]:checked:before {
  background: #00a7a2;
}

.Logodesign_div {
  font-size: 14px;
  font-weight: bold;
  font-family: sans-serif;
  padding: 10px 0;
}

.padding {
  padding: 0 !important;
}

.TxDrago {
  background-image: repeating-linear-gradient(-1deg,
      #00a7a2,
      #00a7a2 10px,
      transparent 10px,
      transparent 18px,
      #00a7a2 18px),
    repeating-linear-gradient(89deg,
      #00a7a2,
      #00a7a2 10px,
      transparent 10px,
      transparent 18px,
      #00a7a2 18px),
    repeating-linear-gradient(179deg,
      #00a7a2,
      #00a7a2 10px,
      transparent 10px,
      transparent 18px,
      #00a7a2 18px),
    repeating-linear-gradient(269deg,
      #00a7a2,
      #00a7a2 10px,
      transparent 10px,
      transparent 18px,
      #00a7a2 18px);
  background-size: 2px 100%, 100% 2px, 2px 100%, 100% 2px;
  background-position: 0 0, 0 0, 100% 0, 0 100%;
  background-repeat: no-repeat;
  border-radius: 5px;
  background-color: #f6fcfc;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 25px 0;
  cursor: pointer;
}

.import_image {
  background-color: #e7f7f6;
  border-radius: 50px;
  padding: 10px;
}

.termsConditionCheckbox {
  margin: 0 !important;
}

.save_as_draft {
  background-color: #f2f2f2;
  font-size: 14px;
  font-weight: bold;
  font-family: sans-serif;
  padding: 15px 0;
  border-radius: 30px;
  border: none;
  margin-right: 10px;
  cursor: pointer;
  color: black;
  width: fit-content;
  width: 160px;
}

.btn-continue {
  background-color: #00a7a2;
  color: white;
  padding: 15px 0;
  border: none;
  border-radius: 30px;
  font-size: 14px;
  font-weight: bold;
  font-family: sans-serif;
  cursor: pointer;
  width: fit-content;
  width: 160px;
}

.all_btns_wrapper {
  display: flex;
  justify-content: flex-end;
}

.offer_Send_success_container {
  text-align: center;
  height: 100vh;
}

.offer_Send_success_container h1 {
  font-size: 30px;
  font-weight: bold;
  font-family: sans-serif;
}

.offer_Send_success_container h4 {
  font-size: 20px;
  font-weight: bold;
  font-family: sans-serif;
  color: #8083a3;
  margin: 10px 0;
  text-align: center;
}

.offer_Send_success_container h6 {
  font-size: 16px;
  font-weight: 300;
  font-family: sans-serif;
  color: #8083a3;
  padding: 0 420px;
  text-align: center;
  line-height: 30px;
  margin: 10px 0 30px 0;
}

.offer_Send_success_container span {
  font-size: 16px;
  font-weight: bold;
  font-family: sans-serif;
  color: #222222;
}

.fixed_width {
  width: 240px !important;
}

/* view All Offer CSS */

.mainStack {
  display: flex;
  flex-direction: column;
  gap: 30px;
  font-family: sans-serif;
}

.mainTitleBox {
  display: flex;
  align-items: center;
  gap: 20px;
}

.backIconButtonBox {
  color: #222;
  font-size: 14px;
  font-weight: 700;
  display: flex;
  align-items: center;
  background-color: #f2f2f2;
  height: 42px;
  border-radius: 23px;
  width: max-content;
  padding: 0 16px;
  gap: 10px;
  cursor: pointer;
}

.backIconButtonBox img {
  width: 20px;
}

.mainTitleBox span {
  font-size: 28px;
  font-weight: 700;
  color: #222;
}

.selectionButtonBox {
  display: flex;
}

.buttonBox {
  background-color: #00a7a2;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 12px;
  color: white;
  padding: 0 14px;
  gap: 10px;
  height: 42px;
  cursor: pointer;
}

.inactiveButtonBox1 {
  background-color: white;
  color: #8083a3;
}

.proposalCountBox {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  aspect-ratio: 1;
  background-color: white;
  border-radius: 50%;
  color: #00a7a2;
  font-size: 10px;
}

.personDetailStack {
  flex-direction: row !important;
  border: 1px solid #e4e6e8;
  border-radius: 14px;
  padding: 24.5px;
  display: flex;
}

.personDetailListStack {
  gap: 30px;
  display: flex;
  flex-direction: column;
}

.imageBox {
  position: relative;
  width: 80px;
  height: 80px;
  margin-right: 30px;
}

.imageInnerBox {
  width: 80px;
  aspect-ratio: 1;
  border-radius: 50%;
}

.imageInnerBox img {
  width: 100%;
  border-radius: 50%;
}

.imageBox svg {
  position: absolute;
  right: 5px;
  bottom: 5px;
  width: 16px;
}

.peronDetailBox {
  min-width: 400px;
  flex-grow: 1;
}

.userNameSpan {
  font-size: 18px;
  color: #222;
  font-weight: 700;
}

.skillLocationBox {
  display: flex;
  align-items: baseline;
  font-size: 12px;
  font-weight: 500;
  color: #8083a3;
  margin: 8px 0;
}

.skillLocationBox img {
  width: 12px;
  aspect-ratio: 1;
}

.locationImageSpan {
  white-space: nowrap;
}

.ratingBox svg {
  margin-right: 2px;
}

.ratingRatioSpan {
  font-size: 12px;
  font-weight: 700;
  color: #222;
  margin-left: 4px;
}

.ratingsCount {
  font-size: 12px;
  color: #8083a3;
}

.experienceBox {
  display: flex;
  margin-top: 20.5px;
  flex-direction: row !important;
  gap: 70px;
}

.experienceBox div {
  display: flex;
  flex-direction: column;
}

.experienceBoxTitle {
  font-size: 12px;
  font-weight: 500;
  color: #8083a3;
}

.experienceBoxValue {
  color: #000;
  font-size: 16px;
  padding-top: 5px;
  font-weight: 700;
  display: flex;
}

.featureBox {
  display: flex;
  gap: 12px;
  margin-top: 20px;
}

.featureInnerBox {
  display: flex;
  align-items: center;
  background-color: #f6f7f7;
  border-radius: 30px;
  height: 30px;
  padding: 0 15px;
  color: #8083a3;
  font-weight: 500;
  font-size: 10px;
}

.actionButtonContainerBox {
  display: flex;
  gap: 10px;
  font-size: 14px;
  flex-wrap: wrap;
}

.actionButtonContainerBox div {
  background-color: #f2f2f2;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 14px;
  color: #222;
  gap: 10px;
  width: 122px;
  height: 46px;
  cursor: pointer;
}

.iconBox {
  width: 46px !important;
  font-size: 14px;
}

.hireButton {
  background-color: #00a7a2 !important;
  color: #fff !important;
}

img[alt="rightArrow"] {
  width: 14px;
}

.Show_more_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

/* END */

/* ADD New milestone CSS  */
.paymentTermsBox {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 30px;
}

.projectTitleContain {
  display: flex;
  flex-direction: row;
  gap: 132px;
  align-items: center;
}

.projectTitleContainLeftBox {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.personNameSpan {
  font-size: 24px;
  color: #222;
  font-weight: 700;
}

.personSkills {
  font-size: 14px;
  font-weight: 500;
  color: #8083a3;
  margin: 10px 0;
}

.addMilestoneButton {
  border: none;
  background-color: #00a7a2;
  white-space: nowrap;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  padding: 10px 25px;
  border-radius: 23px;
  display: flex;
  align-items: center;
  gap: 6px;
  cursor: pointer;
  justify-content: center;
}

.addMilestoneButtonDisabled {
  border: none;
  background-color: #99dcda;
  white-space: nowrap;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  padding: 10px 25px;
  border-radius: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
}

img[alt="addMilestoneIcon"] {
  width: 20px;
}

.milestoneHeadingBox,
.milestoneRowBox {
  display: flex;
  padding: 10px;
  margin-bottom: 16px;
  gap: 10px;
}

.milestoneHeadingBox span {
  color: #8083a3;
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  background: #fff !important;
}

.headingSerialSpan {
  width: 6%;
}

.headingDescriSpan {
  width: 28%;
}

.headingDateSpan {
  width: 28%;
}

.headingTitleSpan {
  width: 20%;
}

.headingAmountSpan {
  width: 26%;
}

.milestoneRowBox {
  border-radius: 10px;
  opacity: 0.7;
  background: #f5f5fa;
  margin-bottom: 4px;
}

.milestoneRowBox span, .milestoneRowBox button {
  border-radius: 30px;
  border: 1px solid #e4e6e8;
  background: #fff;
  padding: 14.5px;
  display: flex;
  align-items: center;
}

.milestoneRowBox span input {
  border: none;
  outline: none;
  color: #222;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  width: 100%;
  background-color: white;
}

.serialSpan {
  border: none !important;
  background: #f5f5fa !important;
}

.titleSpan {
  width: 20%;
}

.descriSpan {
  width: 30%;
}

.dateSpan {
  width: 30%;
  display: flex;
  justify-content: space-between;
}

.amountSpan {
  width: 30%;
  color: #8083a3;
  display: flex;
  gap: 8px;
}

img[alt="deleteIcon"] {
  width: 20px;
  cursor: pointer;
}

img[alt="dateIcon"] {
  width: 17px;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  color: rgba(0, 0, 0, 0);
  opacity: 1;
  display: block;
  background: url("../dateIcon.png") no-repeat;
  border-width: thin;
  background-size: 17px;
}

/* END */


.Praposal_view{
  padding: 6px 10px;
  border-radius: 30px;
  color: white;
  background-color: #00a7a2;
  border: none;
  font-size: 15px;
  cursor: pointer;
}
.Praposal_view .praposal_span{
  border-radius: 50%;
  background-color: white;
  padding: 6px;
  color: #00a7a2;
  font-size: 16px;
  width: 18px;
}
.total_bids {
  padding: 5px 25px;
  font-size: 16px;
  border: none;
  background-color: #f2f2f2;
  border-radius: 30px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}



@media screen and (max-width: 1200px) {
  .offer_Send_success_container h6 {
    padding: 0 300px;
  }
}

@media screen and (max-width: 1000px) {
  .body_wrapper {
    padding: 0 30px;
  }

  .offer_Send_success_container h6 {
    padding: 0 200px;
  }
}

@media screen and (max-width: 804px) {
  .payment_responsive {
    width: 100%;
  }

  .offer_Send_success_container h6 {
    padding: 0 80px;
  }
}
@media(max-width: 490px) {
  .save_as_draft {
    margin-right: 0;
  }
}